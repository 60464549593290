<template>
  <div style="min-height: 880px;">
    <CRow>
      <CCol sm="12" lg="7">
        <div class="form-group form-row d-flex aling-items-center mb-0">
          <label class="col-form-label col-sm-12 col-lg-1 text-right mb-2">{{`${$t('label.date')}`}}</label>
          <div class="input-group col-sm-12 col-lg-4 mb-2">
            <vue-datepicker
              type="datetime"
              header
              :lang="this.$i18n.locale"
              :editable="false"
              :clearable="false"
              format="DD/MM/YYYY HH:mm"
              placeholder="DD/MM/YYYY HH:mm"
              time-title-format="DD/MM/YYYY HH:mm"
              v-model="form.FromDay"
              :disabled-date="validateDateRange"
              :append-to-body="false"
              value-type="format"
              :show-second="false"
              class="w-100"
            >
              <template #icon-calendar>
                <div style="display: none"></div>
              </template>
            </vue-datepicker>
          </div>
          <label class="col-form-label col-sm-12 col-lg-auto text-right mb-2">-</label>
          <div class="input-group col-sm-12 col-lg-4 mb-2">
            <vue-datepicker
              type="datetime"
              header
              :lang="this.$i18n.locale"
              :editable="true"
              :clearable="false"
              format="DD/MM/YYYY HH:mm"
              placeholder="DD/MM/YYYY HH:mm"
              time-title-format="DD/MM/YYYY HH:mm"
              v-model="form.ToDay"
              :disabled-date="validateDateRange"
              :append-to-body="false"
              value-type="format"
              :show-second="false"
              class="w-100"
            >
              <template #icon-calendar>
                <div style="display: none"></div>
              </template>
            </vue-datepicker>
          </div>
          <div class="d-flex align-items-start justify-content-end col-sm-12 col-lg-auto mb-2">
            <CButton
              color="watch"
              square
              size="sm"
              class="mx-1"
              @click="getYardWCargoStatisticsByPackaging(carousel)"
            >
              <CIcon name="cil-search"/>
            </CButton>
            <CButton
              color="danger"
              square
              size="sm"
              @click="Clear"
            >
              <CIcon name="cil-brush-alt"/>
            </CButton>
          </div>
        </div>
      </CCol>
    </CRow>
    <CRow class="m-0">
      <h5 class="col-sm-12 text-center text-bold px-0">{{ $t('label.packaging') }}</h5>
      <CCol sm="12" lg="4" class="px-1 mb-2">
        <label class="text-bold">{{ $t('label.quantity') }}</label>
        <div class="block-gray text-center">
          <div v-if="CurrentStatistic.length != 0">
            <div class="d-flex align-items-center" style="height: 350px;">
              <PieDonut
                Width="100%"
                Height="100%"
                Type="pie"
                :Series="seriesPieQuantity"
                :Label="labeLoadType"
                :Colors="colorsLoadType"
              />
            </div>
            <div class="d-flex align-items-center px-2">
              <ChartBar
                distributed
                horizontal
                scrolly
                maxHeight="350px"
                Width="100%"
                :Height="BarSize"
                :Series="seriesQuantity"
                :Categories="labeLoadType"
                :Colors="colorsLoadType"
                :dataLabels="dataLabelsBar"
              />
            </div>
          </div>
          <div v-else class="d-flex justify-content-center align-items-center box-empty" style="color:#e55353">
            {{ $t('label.noDataAvailable').toUpperCase() }}
          </div>
        </div>
      </CCol>
      <CCol sm="12" lg="4" class="px-1 mb-2">
        <label class="text-bold">{{ $t('label.weight') }} (TON)</label>
        <div class="block-gray text-center">
          <div v-if="CurrentStatistic.length != 0">
            <div class="d-flex align-items-center" style="height: 350px;">
              <PieDonut
                Width="100%"
                Height="350px"
                :Series="seriesPieTon"
                :Label="labeLoadType"
                :Colors="colorsLoadType"
              />
            </div>
            <div class="d-flex align-items-center px-2">
              <ChartBar
                distributed
                horizontal
                scrolly
                maxHeight="350px"
                Width="100%"
                :Height="BarSize"
                :Series="seriesTon"
                :Categories="labeLoadType"
                :Colors="colorsLoadType"
                :dataLabels="dataLabelsBar"
              />
            </div>
          </div>
          <div v-else class="d-flex justify-content-center align-items-center box-empty" style="color:#e55353">
            {{ $t('label.noDataAvailable').toUpperCase() }}
          </div>
        </div>
      </CCol>
      <CCol sm="12" lg="4" class="px-1 mb-2">
        <label class="text-bold">{{ `${$t('label.volume')} (M³)` }}</label>
        <div class="block-gray text-center">
          <div v-if="CurrentStatistic.length != 0">
            <div class="d-flex align-items-center" style="height: 350px;">
              <PieDonut
                Width="100%"
                Height="350px"
                :Series="seriesPieVolume"
                :Label="labeLoadType"
                :Colors="colorsLoadType"
              />
            </div>
            <div class="d-flex align-items-center px-2">
              <ChartBar
                distributed
                horizontal
                scrolly
                maxHeight="350px"
                Width="100%"
                :Height="BarSize"
                :Series="seriesVolumen"
                :Categories="labeLoadType"
                :Colors="colorsLoadType"
                :dataLabels="dataLabelsBar"
              />
            </div>
          </div>
          <div v-else class="d-flex justify-content-center align-items-center box-empty" style="color:#e55353">
            {{ $t('label.noDataAvailable').toUpperCase() }}
          </div>
        </div>
      </CCol>
      <CCol sm="12" class="px-0 mb-2" style="height: 45px; overflow-y: auto;">
        <CRow class="justify-content-center m-0">
          <CCol v-for="(Statistic, index) in CurrentStatistic" :key="index"  sm="12" lg="auto" class="px-1">
            <div 
              class="d-flex align-items-center"
            >
              <div class="circle-legend mr-1" :style="`background: ${Statistic.Color};`"></div>
              <div>{{ statisticLegend(Statistic) }}</div>
            </div>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import GeneralMixin from '@/_mixins/general';
import ChartBar from '../chart/bar'
import PieDonut from '../chart/pieDonut'
import { DateFormater, NumberFormater } from '@/_helpers/funciones';

//DATA-------------------------
function data() {
  return {
    CurrentStatistic: [],
  }
}

//methods
async function getYardWCargoStatisticsByPackaging() {
  this.$store.state.yardManagement.loading = true;
  this.CurrentStatistic = [];
  let ReportJson = {
    YardId: this.yardData.YardId,
    DateStart: this.form.FromDay ? DateFormater.formatDateTimeWithoutSlash(this.form.FromDay) : '',
    DateEnd: this.form.ToDay ? DateFormater.formatDateTimeWithoutSlash(this.form.ToDay) : '',
  }

  await this.$http.post('YardWCargoStatistics-by-Packaging', ReportJson, { root: 'ReportJson' })
    .then(response => {
      this.CurrentStatistic = response.data.data ?? [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = false;
    })
}

function validateDateRange(date) {
  return date < this.form.PreviousDate || date > this.form.LaterDate;
}

function statisticLegend(item) {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return item[`PackagingName${_lang}`]
}

async function Clear() {
  await this.$emit('getDateRange');
  await this.getYardWCargoStatisticsByPackaging();
}

//computed
function seriesQuantity() {
  let data = [];
  data.push({ data: this.CurrentStatistic.map(item => item.Quantity) });
  return data;
}

function seriesPieQuantity() {
  return this.CurrentStatistic.map(item => item.QuantityPercentage);
}

function seriesTon() {
  let data = [];
  data.push({ data: this.CurrentStatistic.map(item => item.Weigth) });
  return data;
}

function seriesPieTon() {
  return this.CurrentStatistic.map(item => item.WeigthPercentage);
}

function seriesVolumen() {
  let data = [];
  data.push({ data: this.CurrentStatistic.map(item => item.Volumen) });
  return data;
}

function seriesPieVolume() {
  return this.CurrentStatistic.map(item => item.VolumenPercentage);
}

function labeLoadType() {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return this.CurrentStatistic.map(item => item?.[`PackagingName${_lang}`] ?? '');
}

function colorsLoadType() {
  return this.CurrentStatistic.map(item => item.Color);
}

function dataLabelsBar() {
  return {
    formatter: function (val, opts) {
      return NumberFormater.formatNumber(val, 2);
    },
  }
}

function BarSize() {
  let size = this.CurrentStatistic.length;
  return size > 7 ? `${(size*350)/7}px` : '100%'
}

export default {
  name: 'warehouse-yard-situation-packaging',
  data,
  mounted: getYardWCargoStatisticsByPackaging,
  components: {
    ChartBar,
    PieDonut,
  },
  mixins: [GeneralMixin],
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    active: Boolean,
  },
  methods: {
    getYardWCargoStatisticsByPackaging,
    validateDateRange,
    statisticLegend,
    Clear,
  },
  computed: {
    seriesQuantity,
    seriesPieQuantity,
    seriesTon,
    seriesPieTon,
    seriesVolumen,
    seriesPieVolume,
    labeLoadType,
    colorsLoadType,
    dataLabelsBar,
    BarSize,
    ...mapState({
      yardData: state => state.yardManagement.yardData,
      yardCollapse: state => state.yardManagement.yardCollapse,
    })
  },
  watch: {
    active: async function(newVal) {
      if(newVal) {
        await this.getYardWCargoStatisticsByPackaging();
      }
    },
  }
};
</script>
<style scoped>
.block-gray{
  width: 100%;
  background: rgb(198, 198, 201);
  padding-bottom: 1rem !important;
  padding-top: 0rem !important;
  border-radius: 5px;
}
.box-empty{
  width: 100%;
  height: 702px;
  padding-top: 1rem !important;
}
</style>